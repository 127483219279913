import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';
import {parseNullableNumber} from 'utils/fields';
import {sortByTitle as sortAreas} from 'utils/areas';

export const parseUrlQuery = query => {
	const vals = evolve(
		{
			_page: Number,
			service: val => (val ? JSON.parse(val) : []),
			utmSource: val => (val ? JSON.parse(val) : []),
			utmMedium: val => (val ? JSON.parse(val) : []),
			utmCampaign: val => (val ? JSON.parse(val) : []),
			utmContent: val => (val ? JSON.parse(val) : []),
			gclid: val => (val ? JSON.parse(val) : false),
			products: val => (val ? JSON.parse(val) : []),
			trashed: val => (val ? JSON.parse(val) : false),
			teamId: parseNullableNumber,
			salesmanId: parseNullableNumber,
			organizationId: val => (val ? JSON.parse(val) : []),
			marketingSourceId: val => (val ? JSON.parse(val) : []),
			marketingChannelId: val => (val ? JSON.parse(val) : []),
			county: val => (val ? JSON.parse(val) : null),
			city: val => (val ? JSON.parse(val) : null),
			domains: val => (val ? JSON.parse(val) : []),
			tags: val => (val ? JSON.parse(val) : []),
		},
		query,
	);

	return {leadsQuery: replace(vals, initState.leadsQuery)};
};

export const formatUrlQuery = query => {
	const {
		products,
		service,
		utmSource,
		utmMedium,
		utmCampaign,
		utmContent,
		organizationId,
		marketingSourceId,
		marketingChannelId,
		county,
		city,
		domains,
		tags,
		...q
	} = query;

	return {
		...q,
		products: products ? JSON.stringify(products) : null,
		service: service ? JSON.stringify(service) : null,
		utmSource: utmSource ? JSON.stringify(utmSource) : null,
		utmMedium: utmMedium ? JSON.stringify(utmMedium) : null,
		utmCampaign: utmCampaign ? JSON.stringify(utmCampaign) : null,
		utmContent: utmContent ? JSON.stringify(utmContent) : null,
		organizationId: organizationId ? JSON.stringify(organizationId) : null,
		marketingSourceId: marketingSourceId ? JSON.stringify(marketingSourceId) : null,
		marketingChannelId: marketingChannelId ? JSON.stringify(marketingChannelId) : null,
		county: county ? JSON.stringify(county) : null,
		city: city ? JSON.stringify(city) : null,
		domains: domains ? JSON.stringify(domains) : null,
		tags: tags ? JSON.stringify(tags) : null,
	};
};

export const formatFetchableLeadsQuery = ({
	leadsQuery,
	user,
	activeOrganizationId,
	showAllMarketingLeads,
	showActiveOrganizationMarketingLeads,
}) => {
	const {sortCol, sortDir, county, city, ...fetchableQuery} = leadsQuery;

	fetchableQuery._sort = sortDir === 'desc' ? `-${sortCol}` : sortCol;

	if (city) {
		fetchableQuery.areaId = city.id;
	} else if (county) {
		fetchableQuery.areaId = county.id;
	}

	// show all socialMediaLeads for admins and marketing lead handlers
	if (showAllMarketingLeads) {
		return fetchableQuery;
	} else if (showActiveOrganizationMarketingLeads) {
		return {
			...fetchableQuery,
			handlerId: null,
			organizationId: activeOrganizationId,
		};
	} else {
		return {...fetchableQuery, handlerId: user.id};
	}
};

export const formatLeadsFilterFormOutput = ({form}) => {
	return form;
};

export const formatLeadsFilterFormInput = ({leadsQuery}) => {
	const {_page, ...query} = leadsQuery;
	return query;
};

export const formatSummary = (summary = {}) => {
	const {
		leads,
		contacted,
		bookedVisits,
		successfulVisits,
		offers,
		sales,
		salePrice,
		cancelledSales,
	} = summary;

	const newSummary = [
		{label: 'Leads [alt]', value: leads},
		{label: '', value: (contacted / leads) * 100, unit: 'percentage'},
		{label: 'Contacted', value: contacted},
		{label: '', value: (bookedVisits / contacted) * 100, unit: 'percentage'},
		{label: 'Booked visits', value: bookedVisits},
		{label: '', value: (successfulVisits / bookedVisits) * 100, unit: 'percentage'},
		{label: 'Succesful visits', value: successfulVisits},
		{label: '', value: (offers / successfulVisits) * 100, unit: 'percentage'},
		{label: 'Offers (pcs.)', value: offers},
		{label: '', value: (sales / offers) * 100, unit: 'percentage'},
		{label: 'Sales (pcs.)', value: sales},
		{
			label: '',
			value: (cancelledSales / (sales + cancelledSales)) * 100,
			unit: 'percentage',
		},
		{label: 'Cancelled sales (pcs.)', value: cancelledSales},
		{label: 'Sales ({unit})', value: salePrice, unit: 'currency'},
		{label: 'Sales (%)', value: (sales / leads) * 100, unit: 'percentage'},
	];

	return newSummary;
};

export const formatAreas = areas => {
	const _areas = sortAreas(areas);
	const counties = _areas.filter(a => a.type === 'county');
	const cities = _areas.filter(a => a.type === 'city');

	return {
		counties,
		cities,
	};
};
