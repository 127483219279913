import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';
import {
	showAllSalesmanagerLeads,
	showOwnSalesmanagerLeads,
	showOwnOrgSalesmanagerLeads,
} from './selectors';
import {parseNullableNumber, parseNullableString} from 'utils/fields';

export const parseUrlQuery = query => {
	const vals = evolve(
		{
			_page: Number,
			handlerLeads: str => (str ? JSON.parse(str) : false),
			marketingSourceId: parseNullableNumber,
			marketingCategoryId: parseNullableNumber,
			marketingCustomerTypeId: parseNullableNumber,
			phone: parseNullableString,
			clientName: parseNullableString,
		},
		query,
	);

	// dont allow users to change url params without permissions
	if (
		vals.handlerLeads === true &&
		(!showOwnSalesmanagerLeads ||
			!showAllSalesmanagerLeads ||
			!showOwnOrgSalesmanagerLeads)
	) {
		vals.handlerLeads = false;
	}

	return {leadsQuery: replace(vals, initState.leadsQuery)};
};

export const formatFetchableLeadsQuery = ({
	leadsQuery,
	userId,
	showOwnSalesmanagerLeads,
	showAllSalesmanagerLeads,
	showOwnOrgSalesmanagerLeads,
	organizationId,
}) => {
	if (
		(showOwnSalesmanagerLeads ||
			showAllSalesmanagerLeads ||
			showOwnOrgSalesmanagerLeads) &&
		leadsQuery.handlerLeads
	) {
		if (showAllSalesmanagerLeads) {
			return leadsQuery;
		} else if (showOwnOrgSalesmanagerLeads) {
			return {...leadsQuery, organizationId};
		} else {
			return {...leadsQuery, handlerId: userId};
		}
	} else {
		// Minun luomat
		return {...leadsQuery, userId};
	}
};
