import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {formatLeadsFilterFormOutput} from './utils';
import removeUnassignedProperties from 'utils/remove-unassigned-props';
import {sortByTitle as sortAreas} from 'utils/areas';
import {always} from 'ramda';
import {replaceQuery} from 'io/history';
import {emptyLeadsFilters} from './constants';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, effects.destroy()];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				leadsLoading: true,
				leadsQuery: {...state.leadsQuery, _page: payload},
			};

			return [newState, effects.updateLeads()];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		case actions.removeLead.type: {
			return [state, effects.removeLead(payload)];
		}

		case actions.searchLeads.type: {
			const newState = {
				...state,
				leadsLoading: true,
				leadsQuery: {
					...state.leadsQuery,
					_page: 1,
					...formatLeadsFilterFormOutput({form: payload}),
				},
			};

			return [newState, effects.updateLeads()];
		}

		case actions.updateLeadsQuery.type: {
			const newState = {
				...state,
				leadsLoading: true,
				leadsQuery: {...state.leadsQuery, ...payload},
			};

			return [newState, effects.updateLeads()];
		}

		case actions.restoreLead.type: {
			return [state, effects.restoreLead(payload)];
		}

		case actions._setSources.type: {
			return [{...state, sources: payload}, null];
		}

		case actions._setProducts.type: {
			return [{...state, products: payload}, null];
		}

		case actions._setOrganizations.type: {
			return [
				{...state, organizations: payload.filter(o => [1, 2, 3].includes(o.id))},
				null,
			];
		}

		case actions._setUtmOptions.type: {
			const {campaigns, contents, domains} = payload;
			return [{...state, utmCampaigns: campaigns, utmContents: contents, domains}, null];
		}

		case actions.clearQuery.type: {
			const newQuery = {
				...state.leadsQuery,
				...emptyLeadsFilters,
			};

			replaceQuery(always(removeUnassignedProperties(newQuery)));

			return [
				{
					...state,
					leadsQuery: newQuery,
				},
				null,
			];
		}

		case actions.openDeleteLeadModal.type: {
			return [{...state, deleteLeadModalOpen: true, leadToDelete: payload}, null];
		}

		case actions.closeDeleteLeadModal.type: {
			return [{...state, deleteLeadModalOpen: false, leadToDelete: null}, null];
		}

		case actions.openCommentModal.type: {
			return [{...state, commentModalOpen: true, leadToComment: payload}, null];
		}

		case actions.closeCommentModal.type: {
			return [{...state, commentModalOpen: false, leadToComment: null}, null];
		}

		case actions.updateLead.type: {
			return [{...state, processing: true}, effects.updateLead(payload)];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._updateLeadsQuery.type: {
			const newState = {
				...state,
				leadsLoading: true,
				leadsQuery: {...state.leadsQuery, ...payload},
			};

			return [newState, null];
		}

		case actions._setLeads.type: {
			const {data, pagination} = payload;
			const newState = {
				...state,
				leads: data,
				leadsPagination: pagination,
				leadsLoading: false,
				processing: false,
			};
			return [newState, null];
		}

		case actions._removedLead.type: {
			return [
				{...state, deleteLeadModalOpen: false, leadToDelete: null, processing: false},
				null,
			];
		}

		case actions._setProcessing.type: {
			return [{...state, processing: payload}, null];
		}

		case actions._opFailed.type: {
			return [{...state, leadsLoading: false}, null];
		}

		case actions._setHandlers.type: {
			return [{...state, handlers: payload}, null];
		}

		case actions._leadUpdated.type: {
			const leads = state.leads.map(l => (l.id === payload.id ? {...l, ...payload} : l));

			return [{...state, leads, processing: false, commentModalOpen: false}, null];
		}

		case actions._leadHandlerSet.type: {
			const {leadId, handler} = payload;

			const leads = handler
				? state.leads.map(l =>
						l.id === leadId ? {...l, handlerId: handler.id, salesmanager: handler} : l,
				  )
				: state.leads;

			return [{...state, leads}, null];
		}

		case actions._setAreas.type: {
			return [{...state, areas: sortAreas(payload)}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
