import {pipe, prop, map} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const postRestoreLead = lid =>
	httpJson('post', `/leads/${lid}/restore`, {}, {}).catch(
		describeThrow(intl.formatMessage({id: 'Failed to restore'})),
	);

export const getProducts = () =>
	httpJson('get', '/products/all', {
		_limit: 999,
		include: 'organizations',
	}).catch(describeThrow(intl.formatMessage({id: 'Product search failed'})));

export const getOrganizations = () =>
	httpJson('get', '/organizations', {
		_limit: 999,
	}).catch(describeThrow(intl.formatMessage({id: 'Organization search failed'})));

// TODO: handler can be salesmanager
export const getHandlers = () =>
	httpJson('get', '/users', {_limit: 999, permissions: 'leads.marketing-lead-handler'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), map(normalize.user)));

export const putLead = lead =>
	httpJson('put', `/leads/${lead.id}`, {include: 'handler'}, {body: lead})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save lead'})))
		.then(pipe(prop('data'), normalize.lead));

export const getAreas = () =>
	httpJson('get', '/areas', {
		type: 'county,city',
		select: 'id,title,type,county',
		withoutTransform: true,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));
