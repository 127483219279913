import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatLeadEventFormInput} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const buildings = pipe(root, prop('buildings'));

export const clients = pipe(root, prop('clients'));

export const lead = pipe(root, prop('lead'));

export const leadFormInitialValues = pipe(root, prop('leadFormInitialValues'));

export const loadingLeadForm = pipe(root, prop('loadingLeadForm'));

export const loadingBuildings = pipe(root, prop('loadingBuildings'));

export const processing = pipe(root, prop('processing'));

export const products = pipe(root, prop('products'));

export const organizations = pipe(root, prop('organizations'));

export const addBuildingModalOpen = pipe(root, prop('addBuildingModalOpen'));

export const addBuildingFormInitValues = pipe(root, prop('addBuildingFormInitValues'));

export const buildingToAdd = pipe(root, prop('buildingToAdd'));

export const mapModalOpen = pipe(root, prop('mapModalOpen'));

export const leadEvents = pipe(root, prop('leadEvents'));

export const leadEventsLoading = pipe(root, prop('leadEventsLoading'));

export const eventModalOpen = pipe(root, prop('eventModalOpen'));

export const leadEventFormInitValues = createSelector(lead, lead =>
	formatLeadEventFormInput(lead),
);

export const salespersons = pipe(root, prop('salespersons'));

export const salesTeams = pipe(root, prop('salesTeams'));

export const marketingLeadTags = pipe(root, prop('marketingLeadTags'));

export const handlers = pipe(root, prop('handlers'));

export const duplicateLeads = pipe(root, prop('duplicateLeads'));

export const duplicateLeadsLoading = pipe(root, prop('duplicateLeadsLoading'));
