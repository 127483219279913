import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import * as rootSelectors from 'modules/common/selectors';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {
	formatLeadFormOutput,
	formatLeadFormInput,
	parseAddBuildingFormInitValues,
	formatAddBuildingOutput,
	formatClientOutput,
	formatClient,
	formatLeadEventFormOutput,
} from './utils';
import {getFormValues, change} from 'redux-form';
import * as normalize from 'utils/normalize';
import {pipe, map, prop} from 'ramda';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize(payload)];
		}

		case actions.reInitialize.type: {
			const id = payload ? payload : null;
			const user = rootSelectors.user(fullState);
			const activeOrganization = rootSelectors.activeOrganization(fullState);
			return [
				{
					...state,
					processing: true,
					leadFormInitialValues: formatLeadFormInput({user, activeOrganization}),
					loadingLeadForm: true,
					lead: initState.lead,
					initialized: false,
				},
				effects.initialize(id),
			];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.saveLead.type: {
			const form = getFormValues('leadForm')(fullState);
			const data = formatLeadFormOutput({form});
			const navigateToTeamCalendar = payload;

			return [
				{...state, processing: true, loadingLeadForm: true},
				effects.saveLead({form: data, navigateToTeamCalendar}),
			];
		}

		case actions._setLead.type: {
			const lead = payload;
			const clients = lead && lead.building ? lead.building.clients : [];
			const user = rootSelectors.user(fullState);
			const activeOrganization = rootSelectors.activeOrganization(fullState);
			const leadFormInitialValues = formatLeadFormInput({
				lead,
				clients,
				user,
				activeOrganization,
			});

			const newState = {
				...state,
				loadingLeadForm: false,
				processing: false,
				lead,
				clients,
				leadFormInitialValues,
			};
			return [newState, null];
		}

		case actions.restoreLead.type: {
			return [{...state, processing: true}, effects.restoreLead(payload)];
		}

		case actions.searchBuildings.type: {
			return [state, effects.searchBuildings(payload)];
		}

		case actions.resetFormField.type: {
			const {field, value} = payload;
			return [state, effects.changeLeadFormFieldValue({field, value})];
		}

		case actions.copyLeadToClient.type: {
			const value = getFormValues('leadForm')(fullState)[payload.from];
			const field = [payload.to];
			return [state, effects.changeLeadFormFieldValue({field, value})];
		}

		case actions.openAddBuildingModal.type: {
			// parse initial address and zip from search string
			const addBuildingFormInitValues = parseAddBuildingFormInitValues(payload);

			return [
				{
					...state,
					addBuildingModalOpen: true,
					addBuildingFormInitValues,
				},
				null,
			];
		}

		case actions.closeAddBuildingModal.type: {
			return [
				{
					...state,
					addBuildingModalOpen: false,
					addBuildingFormInitValues: initState.addBuildingFormInitValues,
				},
				null,
			];
		}

		case actions.openMapModal.type: {
			return [
				{...state, mapModalOpen: true, buildingToAdd: payload},
				effects.initAddBuildingMap(),
			];
		}

		case actions._setBuildingToAddCoords.type: {
			return [{...state, buildingToAdd: {...state.buildingToAdd, coords: payload}}, null];
		}

		case actions.closeMapModal.type: {
			return [
				{...state, mapModalOpen: false, buildingToAdd: initState.buildingToAdd},
				null,
			];
		}

		case actions.addBuilding.type: {
			const building = formatAddBuildingOutput(state.buildingToAdd);
			return [{...state, processing: true}, effects.addBuilding(building)];
		}

		case actions.addClientToBuilding.type: {
			const form = getFormValues('leadForm')(fullState) || null;
			const client = formatClientOutput({form});

			return [{...state, processing: true}, effects.createClient(client)];
		}

		case actions._addClient.type: {
			const clients = [...state.clients, payload];
			return [{...state, clients, processing: false}, null];
		}

		case actions._addBuilding.type: {
			return [
				{
					...state,
					processing: false,
					mapModalOpen: false,
					addBuildingModalOpen: false,
					buildingToAdd: initState.buildingToAdd,
					addBuildingFormInitValues: initState.addBuildingFormInitValues,
					clients: initState.clients,
				},
				null,
			];
		}

		case actions.setBuilding.type: {
			const building = payload;
			const clients = pipe(prop('data'), map(normalize.clients))(building.clients);

			return [{...state, clients}, effects.setFormValues(change('leadForm', 'clientId'))];
		}

		case actions.setProcessing.type: {
			return [{...state, processing: payload}, null];
		}

		case actions.updateClientData.type: {
			const _client = getFormValues('leadForm')(fullState).client || null;
			const lead = state.lead;
			const client = formatClient({client: _client, lead});
			return [{...state, processing: true}, effects.updateClient({client})];
		}

		case actions.openEventModal.type: {
			return [{...state, eventModalOpen: true}, null];
		}

		case actions.closeEventModal.type: {
			return [{...state, eventModalOpen: false}, null];
		}

		case actions.saveLeadEvent.type: {
			const form = getFormValues('leadEventForm')(fullState);
			const {leadEvent, callReminder} = formatLeadEventFormOutput({
				form,
				lead: state.lead,
			});

			return [
				{...state, processing: true},
				effects.saveLeadEvent({leadEvent, callReminder}),
			];
		}

		case actions._clientSaved.type: {
			const clients = state.clients.map(c =>
				c.id === payload.id ? {...c, ...payload} : c,
			);
			return [{...state, processing: false, clients}, null];
		}

		case actions._initialize.type: {
			const products = payload.products.data;
			const lead = payload.lead ? payload.lead : {};
			const clients = lead && lead.building ? lead.building.clients : [];
			const organizations = payload.organizations;
			const user = rootSelectors.user(fullState);
			const activeOrganization = rootSelectors.activeOrganization(fullState);
			const leadFormInitialValues = formatLeadFormInput({
				lead,
				clients,
				user,
				activeOrganization,
			});
			const leadEvents = payload.leadEvents ? payload.leadEvents : [];
			const salespersons = payload.salespersons ? payload.salespersons.data : [];
			const salesTeams = payload.salesTeams ? payload.salesTeams.data : [];
			const marketingLeadTags = payload.tags ? payload.tags : [];
			const handlers = payload.handlers ? payload.handlers : [];
			const duplicateLeads = payload.duplicateLeads ? payload.duplicateLeads.data : [];

			return [
				{
					...state,
					initialized: true,
					processing: false,
					products,
					lead,
					clients,
					organizations,
					leadFormInitialValues,
					loadingLeadForm: false,
					leadEvents,
					leadEventsLoading: false,
					salespersons,
					salesTeams,
					marketingLeadTags,
					handlers,
					duplicateLeads,
					duplicateLeadsLoading: false,
				},
				null,
			];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false, loadingLeadForm: false}, null];
		}

		case actions._setBuildings.type: {
			const buildings = payload;
			return [{...state, buildings, loadingBuildings: false}, null];
		}

		case actions._setProducts.type: {
			return [{...state, products: payload.data}, null];
		}

		case actions._leadEventAdded.type: {
			// if the lead has a calendar resource and saving the event returned it, update it into state. otherwise keep the old value.
			const newCalendarResource = payload.lead.calendarResource
				? normalize.calendarResource(payload.lead.calendarResource)
				: state.lead.calendarResource;

			return [
				{
					...state,
					leadEvents: [payload, ...state.leadEvents],
					lead: {
						...state.lead,
						state: payload.state,
						calendarResource: newCalendarResource,
					},
					processing: false,
					eventModalOpen: false,
				},
			];
		}

		case actions._setMarketingLeadTags.type: {
			return [{...state, marketingLeadTags: payload}, null];
		}

		case actions._setHandlers.type: {
			return [{...state, handlers: payload}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
