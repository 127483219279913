import {evolve} from 'ramda';
import {parseNullableNumber} from 'utils/fields';
import {replace} from 'utils/objects';
import initState from './state';

export const parseUrlQuery = query => {
	const vals = evolve(
		{
			_page: Number,
			state: val => (val ? JSON.parse(val) : []),
			products: val => (val ? JSON.parse(val) : []),
			trashed: str => (str ? JSON.parse(str) : false),
			domains: val => (val ? JSON.parse(val) : []),
			organizationId: parseNullableNumber,
			handlerId: parseNullableNumber,
			cityId: parseNullableNumber,
		},
		query,
	);

	return {leadsQuery: replace(vals, initState.leadsQuery)};
};

export const formatUrlQuery = query => {
	const {state, products, domains, ...q} = query;

	return {
		...q,
		state: state ? JSON.stringify(state) : null,
		products: products ? JSON.stringify(products) : null,
		domains: domains ? JSON.stringify(domains) : null,
	};
};

export const formatFetchableLeadsQuery = ({
	leadsQuery,
	user,
	organizationId,
	showAllMarketingLeads,
	showActiveOrganizationMarketingLeads,
}) => {
	const {sortCol, sortDir, ...fetchableQuery} = leadsQuery;

	fetchableQuery._sort = sortDir === 'desc' ? `-${sortCol}` : sortCol;

	// show all socialMediaLeads for admins and marketing lead handlers
	if (showAllMarketingLeads) {
		return fetchableQuery;
	} else if (showActiveOrganizationMarketingLeads) {
		return {
			...fetchableQuery,
			handlerId: null,
			organizationId: organizationId,
		};
	} else {
		return {...fetchableQuery, handlerId: user.id};
	}
};

export const formatLeadsFilterFormOutput = ({form}) => {
	return form;
};

export const formatLeadsFilterFormInput = ({leadsQuery}) => {
	return {
		createdAtStart: leadsQuery.createdAtStart,
		createdAtEnd: leadsQuery.createdAtEnd,
		dealAtStart: leadsQuery.dealAtStart,
		dealAtEnd: leadsQuery.dealAtEnd,
		domains: leadsQuery.domains,
		products: leadsQuery.products,
		organizationId: leadsQuery.organizationId,
		marketingSourceId: leadsQuery.marketingSourceId,
		utmCampaign: leadsQuery.utmCampaign,
		utmContent: leadsQuery.utmContent,
		state: leadsQuery.state,
		trashed: !!leadsQuery.trashed,
		service: leadsQuery.service,
		handlerId: leadsQuery.handlerId,
		phone: leadsQuery.phone,
		cityId: leadsQuery.cityId,
	};
};
